body {
  overflow-x: hidden;
  margin: 0;
  font-family: "Questrial", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.5s ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grid_links {
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@keyframes chess_loading {
  0%    {transform: translate(0, -80px);}
  25%   {transform: translate(40px, -80px);}
  50%   {transform: translate(40px, -40px);}
  75%   {transform: translate(0px, -40px);}
  100%  {transform: translate(0px, -80px);}
}

#loading_chess_piece {
  animation-name: chess_loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@media only screen and (min-width: 1390px) {
  .home_club_card {
    max-width: 23vw;
  }
}
